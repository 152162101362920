import * as React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import Head from '../components/head';

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-top: -25px;
  padding: 40px 70px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048);

  @media (max-width: 767px) {
    padding: 20px 20px;
    box-shadow: none;
    margin: 0 -15px;
  }
`;

const PostTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 5px;
  color: #000;
  :hover {
    color: rgb(87, 62, 222);
  }
`;

const PostSubTitle = styled.p`
  margin-bottom: 5px;
`;

const Info = styled.div`
  margin-top: 20px;
`;

const GreyText = styled.h3`
  color: #999;
  letter-spacing: 1.3px;
  line-height: 1.5;
`;

const IndexPage = ({ data }) => {
  return (
    <Layout hero>
      <Head />
      <Card>
        <h1>Blog</h1>

        {data.allMdx.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={`${node.fields.slug}`}>
              <PostTitle>{node.frontmatter.title}</PostTitle>
            </Link>
            <PostSubTitle>{node.frontmatter.description}</PostSubTitle>
            <Link to={`${node.fields.slug}`}>Read →</Link>
          </div>
        ))}

        <Info>
          {data.allMdx.edges.length < 1 ? (
            <GreyText>Oops! Nothing to show.</GreyText>
          ) : (
            <Link to="/blog">View all articles</Link>
          )}
        </Info>
      </Card>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMdx(
      limit: 5
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
// #3155dc
/* const {
  site: {
    siteMetadata,
    siteMetadata: {description: siteDescription, keywords: siteKeywords},
  },
} = data
*/
